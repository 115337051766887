import { combineReducers } from '@reduxjs/toolkit';
import accountReducer from '../components/accounts/accountSlice';
import authReducer from '../app/authSlice';
import messageReducer from '../components/messages/messageSlice';
import phoneBookReducer from '../components/home/phoneBookSlice';
import staffReducer from '../components/staff/staffSlice';
import brandReducer from '../brandSlice';
import textMessageReducer from '../components/accounts/textMessages/textMessageSlice';
import customizedProgressLoaderReducer from '../slices/customizedProgressLoaderSlice';
import sentimentMessageReducer from '../components/messages/sentimentMessageSlice';
import accountVerifiedCallerIdReducer from '../components/home/AccountVerifiedCallerIdSlice';

const appReducer = combineReducers({
  account: accountReducer,
  auth: authReducer,
  phonebook: phoneBookReducer,
  message: messageReducer,
  staff: staffReducer,
  brand: brandReducer,
  textMessage: textMessageReducer,
  customizedProgressLoader: customizedProgressLoaderReducer,
  sentimentMessage: sentimentMessageReducer,
  accountVerifiedCallerId: accountVerifiedCallerIdReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
