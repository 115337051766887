export const SENTIMENT_TOPIC_RELEVANCE_THRESHOLD = 0.8;
export const SENTIMENT_CONTENT_SAFETY_THRESHOLD = 0.8;
const ZERO = 0;
const ONE = 1;

const getSentimentColor = (sentiment) => {
  return sentiment === 'POSITIVE' ? '#3EC9C4' : sentiment === 'NEGATIVE' ? '#d26463' : '#6569b0';
};
export const getSentimentCountsBySpeaker = (speechSegmentList) => {
  let sentimentCountsBySpeaker = {};
  try {
    const counts = speechSegmentList.reduce((countsInner, item) => {
      let { speaker } = item;
      const { sentiment } = item;
      speaker = speaker?.trim();
      if (speaker) {
        if (speaker && !countsInner[speaker]) {
          countsInner[speaker] = {};
        }
        countsInner[speaker][sentiment] = (countsInner[speaker][sentiment] || ZERO) + ONE;
      }
      return countsInner;
    }, {});
    sentimentCountsBySpeaker = Object.entries(counts).reduce((result, [speaker, countsInner]) => {
      result[speaker] = Object.entries(countsInner).map(([sentiment, count]) => ({
        label: sentiment,
        value: count,
        color: getSentimentColor(sentiment),
      }));
      return result;
    }, {});
  } catch (e) {
    console.error('Error', e);
  }
  return sentimentCountsBySpeaker;
};
export const getSentimentOverallCount = (speechSegmentList) => {
  let result = [];
  try {
    const sentimentCounts = speechSegmentList.reduce((counts, item) => {
      const { sentiment } = item;
      counts[sentiment] = (counts[sentiment] || ZERO) + ONE;
      return counts;
    }, {});

    result = Object.entries(sentimentCounts).map(([label, value]) => ({
      label,
      value,
      color: getSentimentColor(label),
    }));
  } catch (error) {
    console.error('Error', error);
  }
  return result;
};

export const SpeakerWiseColorCodes = {
  0: '#8968CD',
  1: '#FFA500',
  2: '#7F001E',
  3: '#6666FF',
  4: '#8001FF',
};

export const PossibleEntities = {
  banking_information: 'banking_information',
  blood_type: 'blood_type',
  credit_card_cvv: 'credit_card_cvv',
  credit_card_expiration: 'credit_card_expiration',
  credit_card_number: 'credit_card_number',
  date: 'date',
  date_of_birth: 'date_of_birth',
  drivers_license: 'drivers_license',
  drug: 'drug',
  email_address: 'email_address',
  event: 'event',
  injury: 'injury',
  language: 'language',
  location: 'location',
  medical_condition: 'medical_condition',
  medical_process: 'medical_process',
  money_amount: 'money_amount',
  nationality: 'nationality',
  occupation: 'occupation',
  organization: 'organization',
  password: 'password',
  person_age: 'person_age',
  person_name: 'person_name',
  phone_number: 'phone_number',
  political_affiliation: 'political_affiliation',
  religion: 'religion',
  time: 'time',
  url: 'url',
  us_social_security_number: 'us_social_security_number',
};

export const NonDisplayableEntities = [
  PossibleEntities.us_social_security_number,
  PossibleEntities.credit_card_number,
  PossibleEntities.credit_card_cvv,
  PossibleEntities.credit_card_expiration,
  PossibleEntities.drivers_license,
  PossibleEntities.password,
  PossibleEntities.banking_information,
];
