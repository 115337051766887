import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../PortalApi';
import { setPortalSnackInfo } from '../../app/authSlice';
import { convertObjectToQueryParam } from '../../utils/query';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';

export const sentimentMessageSlice = createSlice({
  name: 'sentimentMessage',
  initialState: {
    sentimentMessages: [],
    wordCloudPattern: '',
    selectedSentimentMessage: null,
    wordCloudHiddenWords: [],
  },
  reducers: {
    setSentimentMessages: (state, action) => {
      state.sentimentMessages = action.payload;
    },
    setWordCloudPattern: (state, action) => {
      state.wordCloudPattern = action.payload;
    },
    setSelectedSentimentMessage: (state, action) => {
      state.selectedSentimentMessage = action.payload;
    },
    setWordCloudHiddenWords: (state, action) => {
      state.wordCloudHiddenWords = action.payload;
    },
  },
});

export const {
  setSentimentMessages,
  setWordCloudPattern,
  setSelectedSentimentMessage,
  setWordCloudHiddenWords,
} = sentimentMessageSlice.actions;

export const sentimentMessagesSelector = (state) => state.sentimentMessage.sentimentMessages;

export const wordCloudPatternSelector = (state) => state.sentimentMessage.wordCloudPattern;
export const selectedSentimentMessageSelector = (state) =>
  state.sentimentMessage.selectedSentimentMessage;
export const wordCloudHiddenWordsSelector = (state) => state.sentimentMessage.wordCloudHiddenWords;

export function getSentimentMessagesForUser(query) {
  return (dispatch) => {
    try {
      dispatch(setCustomizedProgressLoading(true));
      let url = '/message/sentimentAnalysis';
      let queryParams = '';
      queryParams = convertObjectToQueryParam(query);
      url = `${url}/?${queryParams}`;
      PortalApi.call(
        url,
        { method: 'get' },
        (response) => {
          const messages =
            response && response['data'] && response['data']['messages']
              ? response['data']['messages']
              : [];
          dispatch(setSentimentMessages(messages));
          const wordCloudHiddenWords =
            response && response['data'] && response['data']['wordCloudHiddenWords']
              ? response['data']['wordCloudHiddenWords']
              : [];
          dispatch(setWordCloudHiddenWords(wordCloudHiddenWords));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          dispatch(setCustomizedProgressLoading(false));
          dispatch(setSentimentMessages([]));
          dispatch(setWordCloudHiddenWords([]));
        },
      );
    } catch (error) {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Could not load new messages,try again !',
        }),
      );
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export default sentimentMessageSlice.reducer;
