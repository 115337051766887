import { Button } from '@mui/material';

import { styled } from '@mui/material/styles';

import GetAppIcon from '@mui/icons-material/GetApp';
import { exportJsonDataToCSVFile } from '../../../utils/fileSaver';
import { useDispatch } from 'react-redux';
import { setPortalSnackInfo } from '../../../app/authSlice';

const PREFIX = 'DownloadOnCallScheduleContacts';

const classes = {
  downloadContactsButton: `${PREFIX}-downloadContactsButton`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.downloadContactsButton}`]: {
    textTransform: 'none',
  },
}));

const DownloadOnCallScheduleContacts = ({ contacts }) => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    try {
      const data = [];
      Object.values(contacts).forEach((con) => {
        const each = {
          Prefix: con['namePrefix'] || '',
          Name: con['name'] || '',
          Schedulable: con['schedulable'] ? 'Yes' : 'No',
        };
        data.push(each);
      });
      exportJsonDataToCSVFile({ jsonData: data, filename: 'UserContacts' });
    } catch (error) {
      console.error('Error', error);
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, something went wrong while downloading file. Please contact IT.',
        }),
      );
    }
  };

  return (
    <StyledButton
      className={classes.downloadContactsButton}
      color="inherit"
      endIcon={<GetAppIcon />}
      onClick={handleDownload}
    >
      Download Contacts
    </StyledButton>
  );
};

export default DownloadOnCallScheduleContacts;
