import { styled } from '@mui/material/styles';
import { Routes, Route, Navigate } from 'react-router-dom';
import CustomAppBar from './components/CustomAppBar';
import General from './components/home/General';
import Messaging from './components/messages';
import Accounts from './components/accounts/Accounts';
import Staff from './components/staff/';
import Profile from './components/home/Profile';
import LandingPage from './components/home/LandingPage';
import { useAuth0 } from '@auth0/auth0-react';
import PortalSnackBar from './components/ui/PortalSnackBar';
import { Audio } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { auth0Loading, setLoading } from './app/authSlice';
import StaffProtectedRoutes from './auth/StaffProtectedRoutes';
import { Backdrop, Grid } from '@mui/material';

const PREFIX = 'AppRouter';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  profileAvatar: `${PREFIX}-profileAvatar`,
  rootContainer: `${PREFIX}-rootContainer`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  [`& .${classes.profileAvatar}`]: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },

  [`& .${classes.rootContainer}`]: {},
}));

const AppRouter = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const open = useSelector(auth0Loading);
  const handleClose = () => {
    dispatch(setLoading(false));
  };

  // If auth0 loading status is not yet true, render nothing
  if (isLoading) {
    return null;
  }

  const appAuthenticatedRoutes = [
    { id: 'home', path: '/home', exact: true, element: General },
    { id: 'messaging', path: '/messaging', element: Messaging },
    { id: 'accounts', path: '/accounts/:id?', element: Accounts },
    { id: 'settings', path: '/settings', element: Profile },
  ];

  const staffOnlyRoutes = [{ id: 'staff', path: '', element: Staff }];

  return (
    <StyledRoot>
      <CustomAppBar status="login" />
      <Grid container style={{ display: 'block' }}>
        <Grid className={classes.rootContainer} item lg={12} xl={12}>
          <Routes>
            <Route element={<LandingPage />} exact path="/" />
            {isAuthenticated &&
              appAuthenticatedRoutes.map((route) => (
                <Route
                  element={<route.element />}
                  exact={route.exact || false}
                  key={route.id}
                  path={route.path}
                />
              ))}

            {isAuthenticated && (
              <Route element={<StaffProtectedRoutes />} path="/staff">
                {staffOnlyRoutes.map((route) => (
                  <Route
                    element={<route.element />}
                    exact={route.exact || false}
                    key={route.id}
                    path={route.path}
                  />
                ))}
              </Route>
            )}

            {/* Fallback routes for non authenticated status */}
            {!isAuthenticated && <Route element={<Navigate to="/" />} path="*" />}

            {/* Fallback routes for authenticated users: Redirects authenticated users to /home for any unmatched routes*/}
            {isAuthenticated && <Route element={<Navigate replace to="/home" />} path="*" />}
          </Routes>
        </Grid>
      </Grid>
      <PortalSnackBar />

      <Backdrop className={classes.backdrop} onClick={handleClose} open={open}>
        <Audio
          color="#00BFFF"
          height={100}
          timeout={3000} //3 secs
          type="Grid"
          width={100}
        />
      </Backdrop>
    </StyledRoot>
  );
};

export default AppRouter;
