export const isLocalhost = () => {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  );
};

/**
 *
 * @returns boolean value to determine if the environment running is local or one of many development vm hosted urls.
 */
export const isLocalOrDevEnvironment = () => {
  return (
    isLocalhost() ||
    window.location.origin === 'https://devportal3.mapcom.local' ||
    window.location.origin === 'https://devportal-v2.mapcom.local' ||
    window.location.origin === 'https://portal-v2.mapcom.local' ||
    window.location.origin === 'https://testportal-v2.mapcom.local'
  );
};

/**
 *
 * @returns backend url to be used when serving project in localhost:3000
 */
export const getCurrentDevUrl = () => {
  return 'https://devportal3.mapcom.local';
  // return 'http://localhost:8080';
};

/**
 * allowedUserLogins
 * @returns true only if allowedUserLogins is among
 */
export const allowOnlyITTeamPortalUserLogins = (portalUserLogin, allowedUserLogins) => {
  try {
    const permittedUserLogins = allowedUserLogins
      ? allowedUserLogins
      : getAllITTeamPortalUserLogins();
    return permittedUserLogins.includes(portalUserLogin);
  } catch {
    return false;
  }
};

/**
 * list of username of users to restrict some features
 */
export const ITTeamPortalUserLogins = {
  SALLURI: 'SALLURI',
  KSWAMY: 'KSWAMY',
  VDANI: 'VDANI',
  SSARNAVSKIY: 'SSARNAVSKIY',
  RDHUNGANA: 'RDHUNGANA',
  TESTCASE105: 'TESTCASE105',
};

export const getAllITTeamPortalUserLogins = () => {
  return Object.values(ITTeamPortalUserLogins);
};
