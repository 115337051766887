/*
 * Calls to the posh backend
 */

// eslint-disable-next-line
import { getCurrentDevUrl } from './utils/urls';
//import store from './app/store'
// eslint-disable-next-line

/* Default to logging any errors */
export let defaultErrorHandler = (error) => {
  console.log(error);
};

/* Function to get identity key */
export let sessionKey = () => {
  //const token = useSelector(state => state.auth.token);
  // return store.getState().auth.token;
  return localStorage.access_token;
};

/* Function to get identity key */
export const userEmail = () => {
  //const token = useSelector(state => state.auth.token);
  // return store.getState().auth.token;
  return JSON.parse(localStorage.user).user.email;
};

/*
 * Set an error handler and a function to get an identity key
 */
export function setup(errorHandler, getSessionKey) {
  defaultErrorHandler = errorHandler;
  sessionKey = getSessionKey;
}

/*
 * Posh base URL
 */
export function BaseUrl() {
  if (window.location.host === 'localhost:3000') {
    return `${getCurrentDevUrl()}/api`;
  } else if (window.location.host === 'portal-v2.mapcom.local') {
    return 'https://portal-v2.mapcom.local/api';
  } else if (window.location.host === 'devportal3.mapcom.local') {
    return 'https://devportal3.mapcom.local/api';
  } else if (window.location.host === 'prod4portalapp.mapcom.local') {
    return 'https://prod4portalapi.mapcom.local/api';
  } else if (window.location.host === 'prod5portalapp.mapcom.local') {
    return 'https://prod5portalapi.mapcom.local/api';
  } else if (window.location.host === 'prod6portalapp.mapcom.local') {
    return 'https://prod6portalapi.mapcom.local/api';
  } else if (window.location.host === 'eqx-portalapp1.mapcom.local') {
    return 'https://eqx-portalapi1.mapcom.local/api';
  } else if (window.location.host === 'eqx-portalapp2.mapcom.local') {
    return 'https://eqx-portalapi2.mapcom.local/api';
  } else if (window.location.host === 'qtsportalapp1.dc1.dmz.mapcom.local') {
    return 'https://qtsportalapi1.dc1.dmz.mapcom.local/api';
  } else if (window.location.host === 'qtsportalapp2.dc1.dmz.mapcom.local') {
    return 'https://qtsportalapi2.dc1.dmz.mapcom.local/api';
  } else if (window.location.host === 'eqxportalapp3.dc2.dmz.mapcom.local') {
    return 'https://eqxportalapi3.dc2.dmz.mapcom.local/api';
  } else if (window.location.host === 'eqxportalapp4.dc2.dmz.mapcom.local') {
    return 'https://eqxportalapi4.dc2.dmz.mapcom.local/api';
  } else {
    return `https://${window.location.host}/api`;
  }
}

/*
 * Web socket URL
 */
export function WebSocket() {
  const url = BaseUrl();
  if (url.startsWith('https://')) {
    return `wss://${url.substring(8)}/update`;
  }
  return `ws://${url.substring(7)}/update`;
}

/*
 * Make an HTML form an object
 */
export function makeForm(formObject) {
  let form = '';
  const keyNames = Object.keys(formObject);
  for (let i = 0; i < keyNames.length; i++) {
    if (typeof formObject[keyNames[i]] !== 'undefined') {
      if (i > 0) {
        form += '&';
      }
      if (formObject[keyNames[i]] instanceof Date) {
        form += `${keyNames[i]}=${formObject[keyNames[i]].toISOString()}`;
      } else {
        form += `${keyNames[i]}=${encodeURIComponent(formObject[keyNames[i]])}`;
      }
    }
  }
  return form;
}

function getContent(response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType.includes('application/json')) {
    return response.json();
  } else if (contentType.includes('application/octet-stream')) {
    return response.blob();
  }
  return response.text();
}

/*
 * Call an AgentApi web service.  This handles adding the appropriate
 * API key into the header if the agent is logged in.
 *
 * url - The URL to the web service minus the base. For example
 *       /api/agent/me.
 * parameters - Fetch parameters
 * successHandler - If passed, called for a 2xx response and passed
 *   the content, and the status code.  The content will usuall be
 *   JSON or a string depending on the content type returned by the
 *   server
 * errorHandler - If passed called for any other responses.  It's
 *   passed an error message, content, and status code.  The agent API
 *   passes errors back in the X-POSH-ERROR header.  If this is not
 *   passed the error message is displayed to the user.  An error handler
 *   can also call PoshApi.defaultErrorHandler to display to the user and
 *   do additional handling.
 * logFunctionName - A name of the function being called that's
 *   include with the error message
 *
 *
 */
export function call(url, parameters, successHandler, errorHandler, logFunctionName) {
  if (!parameters.headers) {
    parameters.headers = {};
  }
  if (!parameters.headers['Authorization']) {
    parameters.headers['Authorization'] = `Bearer ${sessionKey()}`;
  }

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('xauth') && urlParams.get('xauth') !== '') {
    parameters.headers['X-AUTH-IMP'] = urlParams.get('xauth');
    sessionStorage.setItem('xauth', urlParams.get('xauth'));
  }

  if (sessionStorage.getItem('xauth') && sessionStorage.getItem('xauth') !== '') {
    parameters.headers['X-AUTH-IMP'] = sessionStorage.getItem('xauth');
  }

  if (!parameters.headers['Content-Type'] && parameters.body) {
    parameters.headers['Content-Type'] = 'application/json';
  }

  if (!url.startsWith('http')) {
    url = BaseUrl() + url;
  } else {
    console.log(`Prepending Base: ${url}`);
  }

  fetch(url, parameters)
    .then((response) => {
      getContent(response).then((content) => {
        if (response.ok) {
          if (typeof successHandler === 'function') {
            successHandler(content, response.status);
          }
        } else if (response.status === 401) {
          window.location.href = `https://${window.location.hostname}/home`;
        } else if (response.status === 406) {
          alert('Impersonation mode does not allow this');
        } else {
          const poshError = response.headers.get('X-POSH-ERROR');
          if (poshError) {
            if (typeof errorHandler === 'function') {
              errorHandler(poshError, content, response.status);
            } else {
              defaultErrorHandler(poshError);
            }
          } else {
            if (typeof errorHandler === 'function') {
              errorHandler(response.statusText, content, response.status);
            } else {
              defaultErrorHandler(response.statusText);
            }
          }
        }
      });
    })
    .catch((error) => {
      if (typeof errorHandler === 'function') {
        errorHandler(error, undefined, -1);
      } else {
        defaultErrorHandler(error);
      }
    });
}

/*
 * Make a call but return a promise instead of calling handlers
 */
export function callPromise(url, parameters) {
  parameters = parameters || {};
  if (!parameters.headers) {
    parameters.headers = {};
  }
  if (!parameters.headers['Authorization']) {
    parameters.headers['Authorization'] = `Bearer ${sessionKey()}`;
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('xauth') && urlParams.get('xauth') !== '') {
    parameters.headers['X-AUTH-IMP'] = urlParams.get('xauth');
    sessionStorage.setItem('xauth', urlParams.get('xauth'));
  }

  if (sessionStorage.getItem('xauth') && sessionStorage.getItem('xauth') !== '') {
    parameters.headers['X-AUTH-IMP'] = sessionStorage.getItem('xauth');
  }

  if (!parameters.headers['Content-Type'] && parameters.body) {
    parameters.headers['Content-Type'] = 'application/json';
  }

  if (!url.startsWith('http')) {
    url = BaseUrl() + url;
  } else {
    console.log(`Prepending Base: ${url}`);
  }
  console.log('url is', url);
  return fetch(url, parameters);
}
