import * as PortalApi from '../PortalApi';

import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n';

const startState = {
  user: {},
  token: '',
  fcmToken: '',
  portalUser: {},
  auth0: false,
  metadata: {},
  timezone: 'America/New_York',
  timezones: [],
  loading: false,
  portalSnackOpen: false,
  portalSnackInfo: {},
  defaultLanguage: 'en',
  //nonUser:false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: startState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPortalUser: (state, action) => {
      state.portalUser = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setTimezones: (state, action) => {
      state.timezones = action.payload;
    },
    setDefaultLanguage: (state, action) => {
      state.defaultLanguage = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setAuth0: (state, action) => {
      state.auth0 = action.payload;
    },
    setUserMetadata: (state, action) => {
      state.metadata = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPortalSnackOpen: (state, action) => {
      state.portalSnackOpen = action.payload;
    },
    setPortalSnackInfo: (state, action) => {
      state.portalSnackInfo = action.payload;
      state.portalSnackOpen = true;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    // setNonUser:(state,action) =>{
    //   state.nonUser =action.payload;
    // }
  },
});

export const {
  setTimezones,
  setDefaultLanguage,
  setPortalSnackOpen,
  setPortalSnackInfo,
  setLoading,
  setTimezone,
  setPortalUser,
  setUser,
  setUserToken,
  setAuth0,
  setUserMetadata,
  setFcmToken,
} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getUser = (state) => state.auth.user;
export const getUserToken = (state) => state.auth.token;
export const auth0Selector = (state) => state.auth.auth0;
export const getUserMeta = (state) => state.auth.metadata;
export const portalUserSelector = (state) => state.auth.portalUser;
export const timezoneSelector = (state) => state.auth.timezone;
export const timezonesSelector = (state) => state.auth.timezones;
export const auth0Loading = (state) => state.auth.loading;
export const portalSnackOpenSelector = (state) => state.auth.portalSnackOpen;
export const portalSnackInfoSelector = (state) => state.auth.portalSnackInfo;
export const defaultLanguageSelector = (state) => state.auth.defaultLanguage;
export const fcmTokenSelector = (state) => state.auth.fcmToken;
//export const nonUSerSelector = state => state.auth.nonUser;

export function getPortalUser() {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setPortalUser({}));
    const hostname = window.location.hostname.replace('secure2.', '');
    try {
      PortalApi.call(
        `/users/me?brand=${hostname}`,
        { method: 'get' },
        (response) => {
          dispatch(setPortalUser(response));
          dispatch(
            setTimezone(response.timeZone === 'Pakistan' ? 'Asia/Karachi' : response.timeZone),
          );
          dispatch(setLoading(false));
          if (response && response.login && response.optionsJson) {
            var options = JSON.parse(response.optionsJson);
            if (options.customwords) {
              var newWords = {
                'escalation pending': 'Escalation Pending',
                Escalations: 'Escalations',
                Followup: 'Followup',
                'Clear Escalation': 'Clear Escalation',
                'Follow up not required': 'Follow up not required',
                'Follow Up required': 'Follow Up required',
                'Retrieve Only Pending Escalations': 'Retrieve Only Pending Escalations',
                'Retrieve Only Followup Required': 'Retrieve Only Followup Required',
              };

              Object.keys(options.customwords).forEach((element) => {
                if (options.customwords[element] && options.customwords[element].length > 1) {
                  newWords[element] = options.customwords[element];
                }
              });
              i18n.addResourceBundle('en', 'translation', newWords, true, true);
              i18n.changeLanguage('en');
              i18n.reloadResources();
            }
          }
        },
        (error, status, content) => {
          // if((status.message).includes("USERNOTFOUND")){
          //   dispatch(setNonUser(true));
          // }else{
          //   dispatch(setNonUser(false));
          // }
          dispatch(setPortalUser({}));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      console.log(`portal me login response error ${error}`);
      dispatch(setLoading(false));
      dispatch(setPortalUser({}));
    }
  };
}

export function getTimezones() {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setTimezones([]));
    try {
      PortalApi.call(
        '/codes/TIMEZONES',
        { method: 'get' },
        (response) => {
          dispatch(setTimezones(response));
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setTimezones([]));
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setTimezones([]));
    }
  };
}

export function updatePortalUser(user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        '/users/me',
        { method: 'post', body: JSON.stringify(user) },
        (response) => {
          dispatch(getPortalUser());
          dispatch(setLoading(false));
          let message = 'Profile updated!';
          let severity = 'success';
          if (response === 'EMAILUSED') {
            message = 'Email is already in use.';
            severity = 'error';
          } else if (response === 'SHORTPASS') {
            message = 'Password is too short.';
            severity = 'error';
          } else if (response === 'INVALIDLOGIN') {
            message = 'Invalid user login.';
            severity = 'error';
          } else if (response === 'ERROR') {
            message = 'Oops, something went wrong. Please contact customer support.';
            severity = 'error';
          }
          dispatch(setPortalSnackInfo({ severity, message }));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(
            setPortalSnackInfo({ severity: 'error', message: 'Could not update profile details' }),
          );
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setPortalSnackInfo({ severity: 'error', message: 'Could not update profile details' }),
      );
    }
  };
}

export function updatePortalUserPassword(user) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        '/users/password',
        { method: 'post', body: JSON.stringify(user) },
        (stats) => {
          dispatch(getPortalUser());
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'success', message: 'Password changed!' }));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Could not update password' }));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function saveFCMToken(info) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        '/users/fcm',
        { method: 'post', body: JSON.stringify(info) },
        (stats) => {
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export function deleteFCMToken(info) {
  return (dispatch) => {
    dispatch(setLoading(true));
    try {
      PortalApi.call(
        '/users/fcm',
        { method: 'delete', body: JSON.stringify(info) },
        (stats) => {
          dispatch(setLoading(false));
        },
        (error, status, content) => {
          dispatch(setLoading(false));
        },
      );
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
}

export default authSlice.reducer;
