import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Popover, CircularProgress } from '@mui/material';
import {
  downloadRecordings,
  getRecordings,
  msgLoadingSelector,
  recordingDownloadListSelector,
  recordingsSelector,
  selRecFeaturesSelector,
} from '../messageSlice';
import { selReceiverSelector } from '../messageSlice';
import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ListIcon from '@mui/icons-material/List';
import Recording from './Recording';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'react-i18next';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    background: 'transparent',
  },
}));

export default function Recordings() {
  const selReceiver = useSelector(selReceiverSelector);
  const recordings = useSelector(recordingsSelector);
  const disabledFeatures = useSelector(selRecFeaturesSelector);
  const [displayType, setDisplayType] = useState('grid');
  const [type, setType] = useState('Single');
  const [description, setDescription] = useState('');
  const [dateRangeError, setDateRangeError] = useState('');
  const msgLoading = useSelector(msgLoadingSelector);
  const checked = useSelector(recordingDownloadListSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(1);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(1, 'd').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    var current = selectionRange;
    if (moment(ranges.selection.endDate).diff(moment(ranges.selection.startDate), 'days') > 7) {
      setDateRangeError('Cannot load more than 7 days');
      return;
    }
    setDateRangeError('');

    console.log(ranges, '*****');
    current[0].startDate = ranges.selection.startDate;
    current[0].endDate = ranges.selection.endDate;
    setSelectionRange(current);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
      dispatch(
        getRecordings(
          selReceiver,
          moment(ranges.selection.startDate).startOf('day').format('DD-MM-YY HH:mm:ss'),
          moment(ranges.selection.endDate).endOf('day').format('DD-MM-YY HH:mm:ss'),
        ),
      );
      setCount(count + 1);
    }
  };

  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const initDownload = (value) => {
    dispatch(downloadRecordings(checked));
  };

  return (
    <StyledGrid className={classes.root} container>
      <Grid item xs={12}>
        <ButtonGroup color="primary">
          <PortalIconButton
            aria-label="upload picture"
            className={classes.buttonGroup}
            color={displayType === 'grid' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('grid')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="Grid">
              <ViewCompactIcon />
            </Tooltip>
          </PortalIconButton>
          <PortalIconButton
            aria-label="upload picture"
            color={displayType === 'list' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('list')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="List">
              <ListIcon />
            </Tooltip>
          </PortalIconButton>

          <PortalIconButton
            aria-describedby={id}
            aria-label="upload picture"
            className={classes.fab}
            color="white"
            component="button"
            onClick={handleClick}
            variant="contained"
          >
            <DateRangeIcon />
          </PortalIconButton>

          <Typography style={{ alignSelf: 'center' }} variant="caption">
            {moment(selectionRange[0].startDate).startOf('day').format('MMM DD,YYYY hh:mm A z')} -{' '}
            {moment(selectionRange[0].endDate).endOf('day').format('MMM DD,YYYY hh:mm A z')}
          </Typography>

          {disabledFeatures && disabledFeatures.indexOf('RCRDDWNLD') === -1 && (
            <PortalIconButton
              aria-describedby={id}
              aria-label="upload picture"
              className={classes.fab}
              color="white"
              component="button"
              disabled={checked && checked.length === 0}
              onClick={initDownload}
              variant="contained"
            >
              <GetAppIcon />
            </PortalIconButton>
          )}

          <PortalIconButton
            color="secondary"
            href="https://rise.articulate.com/share/tc_bt7Kd6zdiYneQpAdmilS5WTqxDSBQ"
            size="small"
            target="_blank"
            variant="contained"
          >
            <LiveHelpIcon />
          </PortalIconButton>
        </ButtonGroup>

        <Popover
          PaperProps={{
            style: {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DateRangePicker
            inputRanges={[]}
            onChange={handleSelect}
            ranges={selectionRange}
            showMonthAndYearPickers={true}
            showSelectionPreview={true}
          />
          <Typography color="secondary" variant="subtitle1">
            {dateRangeError}
          </Typography>
        </Popover>
      </Grid>

      {msgLoading && (
        <Grid item xs={12}>
          <CircularProgress color="secondary" />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid className={classes.root} container>
          {selReceiver &&
            recordings &&
            recordings
              .filter((row) => row.audioAvailable)
              .map((row, index) => (
                <Recording
                  content={row}
                  downloadble={disabledFeatures && disabledFeatures.indexOf('RCRDDWNLD') === -1}
                  key={index}
                  size={displayType === 'grid' ? 3 : 12}
                />
              ))}
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          {recordings && recordings.length === 0 && (
            <Typography variant="overline">No Recordings Found</Typography>
          )}
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
