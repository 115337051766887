import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { groupBy } from 'lodash';
import SentimentAssemblyAICallTranscription from './AssemblyAICallTranscription';
import SentimentEntitiesDetected from './SentimentEntitiesDetected';
import {
  SENTIMENT_CONTENT_SAFETY_THRESHOLD,
  SENTIMENT_TOPIC_RELEVANCE_THRESHOLD,
  getSentimentCountsBySpeaker,
} from '../../../../utils/assemblyAISentiment';
import SentimentTopicsDetected from './SentimentTopicsDetected';
import SentimentContentSafetyDetected from './SentimentContentSafetyDetected';
import SentimentPieChart from './SentimentPieChart';
import SentimentKeyPhrases from '../SentimentKeyPhrases';

const PREFIX = 'AssemblyAISentimentAnalysis';

const classes = {
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.section}`]: { marginBottom: '5px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '18px' },
}));

export default function AssemblyAISentimentAnalysis({
  message,
  overallSentimentCounts,
  colorCodePerSpeaker,
  displayTranscription,
}) {
  const [transcriptList, setTranscriptList] = useState([]);
  const [entities, setEntities] = useState([]);
  const [keyPhrases, setKeyPhrases] = useState([]);
  const [sentimentCountsBySpeaker, setSentimentCountsBySpeaker] = useState({});
  const [topicsDetected, setTopicsDetected] = useState([]);
  const [contentSafetyDetected, setContentSafetyDetected] = useState([]);

  useEffect(() => {
    if (message && message.SentimentAnalysis && message?.SentimentAnalysis?.length > 0) {
      const sentimentCountsBySpeaker = getSentimentCountsBySpeaker(message?.SentimentAnalysis);
      setSentimentCountsBySpeaker(sentimentCountsBySpeaker);
    } else {
      setSentimentCountsBySpeaker({});
    }

    if (message && message.Transcript && message?.Transcript?.length > 0) {
      setTranscriptList(message?.Transcript);
    } else {
      setTranscriptList([]);
    }
    if (message && message.Entities && message?.Entities?.length > 0) {
      setEntities(groupBy(message?.Entities, 'entity_type'));
    } else {
      setEntities([]);
    }
    if (message && message.TopicsDetectedSummary && message?.TopicsDetectedSummary?.length > 0) {
      setTopicsDetected(
        message.TopicsDetectedSummary.filter(
          (i) => i.relevance >= SENTIMENT_TOPIC_RELEVANCE_THRESHOLD,
        ),
      );
    } else {
      setTopicsDetected([]);
    }

    if (message && message.KeyPhrases && message?.KeyPhrases?.length > 0) {
      setKeyPhrases(message.KeyPhrases);
    } else {
      setKeyPhrases([]);
    }
    if (message && message.ContentSafetySummary && message?.ContentSafetySummary?.length > 0) {
      setContentSafetyDetected(
        message.ContentSafetySummary?.filter(
          (item) => item.confidence >= SENTIMENT_CONTENT_SAFETY_THRESHOLD,
        ),
      );
    } else {
      setContentSafetyDetected([]);
    }
  }, [message]);

  const getCustomSentimentChartConfig = () => {
    return {
      type: 'doughnut3d',
      width: '30%',
      height: 175,
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: '',
          subCaption: '',
          enableSmartLabels: '0',
          startingAngle: '0',
          showPercentValues: '0',
          decimals: '1',
          useDataPlotColorForLabels: '0',
          showLegend: '1',
          showLabels: '0',
          showValues: '0',
          captionPadding: 0,
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          theme: 'fusion',
        },
        data: [],
      },
    };
  };

  return (
    <StyledGrid container>
      <Grid className={classes.section} item xs={12}>
        <SentimentPieChart
          colorCodePerSpeaker={colorCodePerSpeaker}
          customChartConfigs={getCustomSentimentChartConfig()}
          displayOverallSentiment={false}
          overallCaption="Overall"
          overallSentimentCounts={overallSentimentCounts}
          sentimentCountsBySpeaker={sentimentCountsBySpeaker}
        />
      </Grid>
      {displayTranscription && (
        <Grid className={classes.section} item xs={5}>
          <Grid container>
            <Grid item xs={12}>
              <SentimentEntitiesDetected entities={entities} />
            </Grid>
            <Grid item xs={12}>
              <SentimentTopicsDetected topicsDetected={topicsDetected} />
            </Grid>
            <Grid item xs={12}>
              <SentimentContentSafetyDetected contentSafetyDetected={contentSafetyDetected} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!displayTranscription && (
        <>
          <Grid item xs={6}>
            <SentimentEntitiesDetected entities={entities} />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <SentimentTopicsDetected topicsDetected={topicsDetected} />
              </Grid>
              <Grid item xs={12}>
                <SentimentKeyPhrases keyPhrases={keyPhrases} />
              </Grid>
              <Grid item xs={12}>
                <SentimentContentSafetyDetected contentSafetyDetected={contentSafetyDetected} />
              </Grid>
            </Grid>{' '}
          </Grid>
        </>
      )}
      {displayTranscription && (
        <Grid className={classes.section} item xs={7}>
          <SentimentAssemblyAICallTranscription
            colorCodePerSpeaker={colorCodePerSpeaker}
            transcriptList={transcriptList}
          />
        </Grid>
      )}
    </StyledGrid>
  );
}
