export const MasterFeatureTypeRef = {
  ANI: 'ANI',
  BOOKED: 'BOOKED',
  PAINT: 'PAINT',
  ESCAL: 'ESCAL',
  ROSTER: 'ROSTER',
  WEBRECORD: 'WEBRECORD',
  RECORDMBOX: 'RECORDMBOX',
  ECHOAMD: 'ECHOAMD',
  MANDISP: 'MANDISP',
  FRIMMRET: 'FRIMMRET',
  MSGMETAC: 'MSGMETAC',
  WEBREPLY: 'WEBREPLY',
  DUMONLY: 'DUMONLY',
  NOSUBS: 'NOSUBS',
  WBMSGRSTCT: 'WBMSGRSTCT',
  WEBSTMTS: 'WEBSTMTS',
  WEBPMTS: 'WEBPMTS',
  WEBUSAGE: 'WEBUSAGE',
  WEBDIRCTRY: 'WEBDIRCTRY',
  WEBSCHEDL: 'WEBSCHEDL',
  ONCALLDISP: 'ONCALLDISP',
  VPAGE: 'VPAGE',
  SENTIMENT: 'SENTIMENT',
};
