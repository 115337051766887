import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {
  accountSelector,
  setSelAccount,
  selAccountSelector,
  getDirectoryForAccount,
  setSelAccountInfo,
  getAccountsByUser,
  getFuneralServicesList,
} from './accountSlice';
import Usage from './usage/';
import Statements from './statements';
import Payments from './payments';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { FormControl, Paper, TextField } from '@mui/material';
import Directory from './directory';
import OnCallSchedules from './oncall';
import DCChat from './chat/DChoiceChat';
import CustomerServiceForm from '../messages/customerService';
import { Autocomplete } from '@mui/lab';
import FuneralServiceList from './funeralservice';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { useAuth0 } from '@auth0/auth0-react';
import PortalIconButton from '../shared/components/PortalIconButton';
import PortalTab from '../shared/components/PortalTab';

const PREFIX = 'Accounts';

const classes = {
  root: `${PREFIX}-root`,
  bullet: `${PREFIX}-bullet`,
  tab: `${PREFIX}-tab`,
  tabLabel: `${PREFIX}-tabLabel`,
  tabPanelHolder: `${PREFIX}-tabPanelHolder`,
  autoComplete: `${PREFIX}-autoComplete`,
  link: `${PREFIX}-link`,
  formGrid: `${PREFIX}-formGrid`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: 100,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },

  [`& .${classes.bullet}`]: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },

  [`& .${classes.tab}`]: {
    flexGrow: theme.spacing(1),
    margin: theme.spacing(0),
  },

  [`& .${classes.tabLabel}`]: {
    color: '#fff',
    fontWeight: 'bold',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.tabPanelHolder}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
  },
  [`& .${classes.autoComplete}`]: {
    background: 'white',
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'black',
    background: '#fffff0',
  },

  [`& .${classes.formGrid}`]: {
    marginTop: '26px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
}));

const ServiceIcon = () => (
  <img alt="paytrustnow logo" src="../services_o.png" style={{ width: '24px', height: '24px' }} />
);

const tabIdentifier = {
  directory: 'directory',
  usage: 'usage',
  statements: 'statements',
  payments: 'payments',
  onCallSchedules: 'onCallSchedules',
  services: 'services',
  liveChat: 'liveChat',
  customerService: 'customerService',
};

const tabOptions = {
  directory: {
    identifier: tabIdentifier.directory,
    label: 'Directory',
    dataTour: 'rtDirectory',
    tabPanelComponent: Directory,
    componentProps: {},
    iconComponent: FolderSharedIcon,
    displayOrder: 1,
  },
  usage: {
    identifier: tabIdentifier.usage,
    label: 'Usage',
    dataTour: 'rtUsage',
    tabPanelComponent: Usage,
    componentProps: {},
    iconComponent: MultilineChartIcon,
    displayOrder: 2,
  },
  statements: {
    identifier: tabIdentifier.statements,
    label: 'Statements',
    dataTour: 'rtStatements',
    tabPanelComponent: Statements,
    componentProps: {},
    iconComponent: ReceiptIcon,
    displayOrder: 3,
  },
  payments: {
    identifier: tabIdentifier.payments,
    label: 'Payments',
    dataTour: 'rtPayments',
    tabPanelComponent: Payments,
    componentProps: {},
    iconComponent: PaymentIcon,
    displayOrder: 4,
  },
  onCallSchedules: {
    identifier: tabIdentifier.onCallSchedules,
    label: 'Oncall Schedules',
    dataTour: 'rtOnCallSchedules',
    tabPanelComponent: OnCallSchedules,
    componentProps: { type: 'receiver' },
    iconComponent: ScheduleIcon,
    displayOrder: 5,
  },
  services: {
    identifier: tabIdentifier.services,
    label: 'Services',
    dataTour: 'rtServices',
    tabPanelComponent: FuneralServiceList,
    componentProps: {},
    iconComponent: ServiceIcon,
    displayOrder: 6,
  },
  liveChat: {
    identifier: tabIdentifier.liveChat,
    label: 'Live Chat',
    dataTour: 'rtLiveChat',
    tabPanelComponent: DCChat,
    componentProps: {},
    iconComponent: QuestionAnswerIcon,
    displayOrder: 7,
  },
  customerService: {
    identifier: tabIdentifier.customerService,
    label: 'Customer Service',
    dataTour: 'rtAcctCustSrvc',
    tabPanelComponent: CustomerServiceForm,
    componentProps: { type: 'account' },
    iconComponent: PermIdentityIcon,
    displayOrder: 8,
  },
};

const tourStepsOptions = {
  selectAccount: {
    position: 'right',
    selector: '[data-tour="rtSelAcct"]',
    content:
      'If you have multiple accounts, use this down arrow to choose the account you wish to view below.',
    displayOrder: 0,
  },
  directory: {
    selector: '[data-tour="rtDirectory"]',
    content: 'This link shows the company directory and all associated contact methods.',
    displayOrder: 1,
  },
  usage: {
    selector: '[data-tour="rtUsage"]',
    content:
      'The Usage Tab shows Time Usage, Call Counts, the Percentage of Minutes used for individual receivers, etc.  The default view is the most recent 7 days.',
    displayOrder: 2,
  },
  statements: {
    selector: '[data-tour="rtStatements"]',
    content:
      'Here you can view a list of recent statements with an option to view as a PDF.  You are also able to request a Statement of Account.',
    displayOrder: 3,
  },
  payments: {
    selector: '[data-tour="rtPayments"]',
    content: 'Click here to Make a Payment as well as see a payment history.',
    displayOrder: 4,
  },
  onCallSchedules: {
    selector: '[data-tour="rtOnCallSchedules"]',
    content:
      'If you utilize an oncall schedule you can use this tab in order to view and edit your schedule.',
    displayOrder: 5,
  },
  customerService: {
    selector: '[data-tour="rtAcctCustSrvc"]',
    position: 'top',
    content:
      'Use this tab to contact the Customer Service team. You can request follow up communication via email or phone call.',
    displayOrder: 6,
  },
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Paper style={{ flexGrow: 1, width: '100%' }}>{children}</Paper>}
    </div>
  );
};

const Accounts = () => {
  const dispatch = useDispatch();
  const [tabIndexValue, setTabIndexValue] = useState(0);
  const accounts = useSelector(accountSelector);
  const { isAuthenticated } = useAuth0();
  const [open, setOpen] = useState(false);
  const selAccount = useSelector(selAccountSelector);
  const [acAccount, setAcAccount] = useState('');
  const [features, setFeatures] = useState([]);
  const [tabsToRender, setTabsToRender] = useState([]);
  const [tourSteps, setTourSteps] = useState([]);
  const [tourCurrentStep, setTourCurrentStep] = useState(0);
  const { t } = useTranslation();

  const closeTour = () => {
    setOpen(false);
  };

  const startTour = (value) => {
    setOpen(true);
  };

  const handleAccountChange = (newValue) => {
    if (newValue != null) {
      getSelectedAccountData(newValue.number);
      localStorage.setItem('lastSelAccount', newValue.number);
    }
  };

  useEffect(() => {
    dispatch(getAccountsByUser());
  }, [dispatch]);

  /**
   * sets the tabs to render based on features
   * */
  useEffect(() => {
    setTourCurrentStep(0);
    formulateTabsRendering();
  }, [features]);

  /**
   *  renders to Messages tab if current location path is not allowed to display on receiver change
   * */
  useEffect(() => {
    if (
      tabsToRender &&
      tabsToRender?.length > 0 &&
      tabsToRender.findIndex((tab) => tab.displayOrder === tabIndexValue) === -1
    ) {
      handleTabChange(tabOptions.messages, 0);
    }
  }, [tabsToRender]);

  const handleTourStepChange = (currentStep) => {
    setTourCurrentStep(currentStep);
  };

  const formulateTabsRendering = () => {
    let tabsToRender = [tabOptions.customerService];
    let tourStepsToRender = [tourStepsOptions.selectAccount, tourStepsOptions.customerService];
    if (features.includes('WEBDIRCTRY')) {
      tabsToRender = [...tabsToRender, tabOptions.directory];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.directory];
    }
    if (features.includes('WEBUSAGE')) {
      tabsToRender = [...tabsToRender, tabOptions.usage];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.usage];
    }
    if (features.includes('WEBSTMTS')) {
      tabsToRender = [...tabsToRender, tabOptions.statements];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.statements];
    }
    if (features.includes('WEBPMTS')) {
      tabsToRender = [...tabsToRender, tabOptions.payments];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.payments];
    }
    if (features.includes('WEBSCHEDL')) {
      tabsToRender = [...tabsToRender, tabOptions.onCallSchedules];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.onCallSchedules];
    }
    if (features.includes('FUNERAL')) {
      tabsToRender = [...tabsToRender, tabOptions.services];
    }
    if (features.includes('CHAT')) {
      tabsToRender = [...tabsToRender, tabOptions.liveChat];
    }
    setTabsToRender(tabsToRender.sort((tab1, tab2) => tab1.displayOrder - tab2.displayOrder));
    setTourSteps(tourStepsToRender.sort((step1, step2) => step1.displayOrder - step2.displayOrder));
  };

  const handleTabChange = (selectedTab, newValue) => {
    setTabIndexValue(newValue);
  };

  useEffect(() => {
    let defaultAccount = '';
    if (accounts.length > 0) {
      defaultAccount = accounts[0].number;
    }
    let accountNumber = '';
    const lastSelectedAccount = localStorage.getItem('lastSelAccount');
    if (lastSelectedAccount) {
      accountNumber = lastSelectedAccount;
    } else {
      accountNumber = defaultAccount;
    }
    getSelectedAccountData(accountNumber);
  }, [dispatch, accounts.length]);

  const getSelectedAccountData = (accountNumber) => {
    let currentAccount;
    if (accountNumber) {
      const features = [];
      const disabledFeatures = [];
      if (accounts.length > 0) {
        currentAccount = accounts.find((element) => element.number === parseInt(accountNumber));
        if (!currentAccount) {
          currentAccount = accounts[0];
        }
        if (currentAccount) {
          setAcAccount(currentAccount);
          currentAccount.features.forEach((item) => {
            if (item.status === 'ENABLE') {
              features.push(item.masterFeature.featureTypeRef);
            } else {
              disabledFeatures.push(item.masterFeature.featureTypeRef);
            }
          });
          const verifyPaymentsTab = currentAccount.features.filter(
            (element) => element.masterFeature.featureTypeRef === 'WEBPMTS',
          );

          if (verifyPaymentsTab.length === 0) {
            features.push('WEBPMTS');
          }

          if (!disabledFeatures.includes('WEBUSAGE')) {
            features.push('WEBUSAGE');
          }
          if (!disabledFeatures.includes('WEBSTMTS')) {
            features.push('WEBSTMTS');
          }
          if (!disabledFeatures.includes('WEBPMTS')) {
            features.push('WEBPMTS');
          }
          setFeatures(features);
        }
      }
      dispatch(setSelAccountInfo(currentAccount));
      dispatch(setSelAccount(accountNumber));
      dispatch(getDirectoryForAccount(accountNumber, 0));
      dispatch(getFuneralServicesList(accountNumber, 0, 10, 'serviceDate'));
    }
  };

  return (
    <StyledGrid className={classes.root} container>
      {isAuthenticated && (
        <PortalIconButton onClick={startTour} style={{ position: 'absolute', top: 68, right: 20 }}>
          <LiveHelpIcon />
        </PortalIconButton>
      )}
      <Tour
        className={classes.helperText}
        closeButtonAriaLabel="Close"
        disableFocusLock
        getCurrentStep={handleTourStepChange}
        isOpen={open}
        onRequestClose={closeTour}
        startAt={tourCurrentStep}
        steps={tourSteps}
      />

      <Grid className={classes.formGrid} item xs={12}>
        <FormControl className={classes.formControl} fullWidth>
          {accounts && (
            <Autocomplete
              className={classes.autoComplete}
              data-tour="rtSelAcct"
              fullWidth
              getOptionLabel={(option) =>
                option
                  ? `${option?.number} - ${
                      option?.nickName ? option?.nickName?.toUpperCase() : option?.name
                    }`
                  : 'No Account Affiliated'
              }
              id="select-account-autocomplete"
              onChange={(event, newValue) => {
                handleAccountChange(newValue);
                setAcAccount(newValue);
              }}
              options={
                accounts && accounts?.length > 0
                  ? accounts.slice().sort((a, b) => {
                      const nameA = a?.nickName || a?.name || '';
                      const nameB = b?.nickName || b?.name || '';
                      return nameA.localeCompare(nameB);
                    })
                  : []
              }
              renderInput={(params) => {
                if (params) {
                  return <TextField {...params} label={t('Select Account')} variant="outlined" />;
                }
                return null;
              }}
              value={acAccount}
            />
          )}
        </FormControl>
      </Grid>

      <Grid className={classes.tabPanelHolder} item xs={12}>
        <AppBar position="static">
          <Tabs
            aria-label="messaging tabs"
            indicatorColor="secondary"
            scrollButtons="auto"
            textColor="primary"
            value={tabIndexValue}
            variant="scrollable"
          >
            {tabsToRender?.map((tab, index) => (
              <PortalTab
                className={classes.tabLabel}
                data-tour={tab.dataTour}
                icon={tab.iconComponent ? <tab.iconComponent /> : null}
                key={tab.identifier}
                label={t(tab.label)}
                onClick={() => handleTabChange(tab, index)}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabsToRender?.map((tab, index) => (
          <TabPanel
            className={classes.tab}
            index={index}
            key={tab.identifier}
            value={tabIndexValue}
          >
            <tab.tabPanelComponent {...tab.componentProps} account={selAccount} />
          </TabPanel>
        ))}
      </Grid>
    </StyledGrid>
  );
};

export default Accounts;
