import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  TableSortLabel,
  Box,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  addVerifyCallerId,
  callerIdValidationCodeSelector,
  setCallerIdValidationCode,
  verifiedCallerIdsSelector,
} from '../staff/staffSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import {
  accountSearch,
  accountSelector,
  getAccountsByUser,
  searchAccountsSelector,
} from '../accounts/accountSlice';
import {
  selCallerIdAccountSelector,
  setSelAccountVerifiedCallerId,
} from './AccountVerifiedCallerIdSlice';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'AccountVerifiedCallerIds';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AccountVerifiedCallerIds() {
  const callerIds = useSelector(verifiedCallerIdsSelector);

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, setError] = useState('');
  const accounts = useSelector(accountSelector);
  const selCallerIdAccount = useSelector(selCallerIdAccountSelector);
  const searchAccounts = useSelector(searchAccountsSelector);
  const [accountSortKey, setAccountSortKey] = useState('id');
  const [accountSortOrder, setAccountSortOrder] = useState('asc');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [open, setOpen] = useState(false);
  const [callerIdhelperText, setCallerIdhelperText] = useState('Enter 10 digit format');
  const [callerIdError, setCallerIdError] = useState(false);
  const callerIdValidationCode = useSelector(callerIdValidationCodeSelector);
  const [paginationCount, setPaginationCount] = useState(0);
  //const [acAccount, setAcAccount] = useState("")

  const handleAccountChange = (newValue) => {
    console.log(`account changes${newValue}`);
    if (newValue != null) {
      dispatch(setSelAccountVerifiedCallerId(newValue));
      dispatch(accountSearch('', newValue.number, '', '', ''));
    }
  };

  const handleAddVerifiedCallerId = (event, account) => {
    dispatch(setCallerIdValidationCode({}));
    setPhoneNumber('');
    dispatch(setSelAccountVerifiedCallerId(account));
    handleOpen();
  };

  const verifyCaller = () => {
    if (phoneNumber != '') {
      console.log(`sel account${selCallerIdAccount}`);
      dispatch(setCallerIdValidationCode({}));
      dispatch(addVerifyCallerId(`+1${phoneNumber}`, selCallerIdAccount.id));

      //setOpen(false);
    } else {
      setCallerIdError(true);
      setCallerIdhelperText('Caller ID cannot be empty');
    }
  };

  const createSortHandler = (key, type) => {
    if (type === 'Account') {
      if (accountSortKey === key) {
        setAccountSortOrder(accountSortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setAccountSortOrder('asc');
      }
      setAccountSortKey(key);
    }
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      var count = 0;
      accounts.forEach((value) => {
        if (
          value.verifiedCallerId &&
          Object.keys(value.verifiedCallerId).length > 0 &&
          value.verifiedCallerId.verificationStatus == 'VERIFIED'
        ) {
          count++;
        }
      });
      setPaginationCount(count);
    }
  }, [accounts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    console.log('clicked');
    const value = event.target.value;
    switch (event.target.name) {
      case 'phoneNumber':
        if (value.length <= 10) {
          setPhoneNumber(value);
          setCallerIdError(false);
          setCallerIdhelperText('Enter 10 digit format');
        }
        break;
      default:
    }
  };

  const { t } = useTranslation();

  return (
    <StyledRoot className={classes.root}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<AccountCircleIcon />}
            className={classes.cardHeader}
            subheader={<Typography className={classes.error}>{error}</Typography>}
            title={t('Account Verified Caller ID')}
          />
          <CardContent>
            <TableContainer dense>
              <Table aria-label="a dense table" className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    {[
                      { name: t('Account#'), key: 'number' },
                      { name: t('Name'), key: 'name' },
                      { name: t('Caller ID'), key: 'CallerID' },
                    ].map((item) => (
                      <TableCell align="left" className={classes.multiColumnHeader} key={item.key}>
                        {' '}
                        <TableSortLabel
                          active={accountSortKey === item.key}
                          classes={{ active: classes.active, icon: classes.icon }}
                          direction={accountSortOrder === 'asc' ? 'asc' : 'desc'}
                          onClick={() => createSortHandler(item.key, 'Account')}
                        >
                          {item.name}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts &&
                    accounts.length > 0 &&
                    accounts
                      .slice()
                      .sort((a, b) =>
                        accountSortOrder === 'asc'
                          ? a[accountSortKey] > b[accountSortKey]
                            ? 1
                            : -1
                          : b[accountSortKey] > a[accountSortKey]
                            ? 1
                            : -1,
                      )
                      .map((value) => {
                        const labelId = `checkbox-list-secondary-label-${value.id}`;
                        return (
                          value.verifiedCallerId &&
                          Object.keys(value.verifiedCallerId).length > 0 &&
                          value.verifiedCallerId.verificationStatus != 'DISABLED' && (
                            <TableRow
                              className={
                                selCallerIdAccount &&
                                selCallerIdAccount.id &&
                                selCallerIdAccount.id === value.id
                                  ? classes.tableRow
                                  : classes.tableRow
                              }
                              hover
                              key={value.id}
                            >
                              {/* <TableCell align="left" >{value.order}</TableCell> */}
                              <TableCell align="left">{value.number}</TableCell>
                              <TableCell align="left">{value.name}</TableCell>
                              {value.verifiedCallerId &&
                              Object.keys(value.verifiedCallerId).length > 0 &&
                              value.verifiedCallerId.verificationStatus == 'VERIFIED' ? (
                                <TableCell align="left">
                                  <p>{value.verifiedCallerId.phoneNumber}</p>
                                </TableCell>
                              ) : value.verifiedCallerId &&
                                Object.keys(value.verifiedCallerId).length > 0 &&
                                value.verifiedCallerId.verificationStatus != 'DISABLED' ? (
                                <TableCell align="left">
                                  <PortalButton
                                    color="primary"
                                    onClick={(event) => handleAddVerifiedCallerId(event, value)}
                                    variant="contained"
                                  >
                                    Add
                                  </PortalButton>
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  <p>Disabled</p>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              onClose={handleClose}
              open={open}
            >
              <DialogTitle id="alert-dialog-title">Add Verified Caller ID</DialogTitle>
              <DialogContent>
                <DialogContentText />
                <FormControl className={classes.formControl}>
                  <Typography>
                    Registering a Caller ID allows account users to dial out under an office number
                    instead of their personal mobile number using our patch service. An
                    authorization call is required to add a Caller ID:
                  </Typography>
                  <ul>
                    <li>Enter the phone number here and click call.</li>
                    <li>Answer the call and wait for the automated prompt.</li>
                    <li>Enter the six digit verification number.</li>
                    <li>If verification fails, re-enter the number to try again.</li>
                  </ul>
                  <Typography variant="h8">
                    <b>Selected Account</b>
                  </Typography>
                  <Typography>
                    {`${selCallerIdAccount.name} - ${selCallerIdAccount.number}`}
                  </Typography>
                  {callerIdValidationCode && Object.keys(callerIdValidationCode).length > 0 ? (
                    <Typography style={{ textAlign: 'center' }} variant="h6">
                      <b>OTP : {callerIdValidationCode.validationCode}</b>
                    </Typography>
                  ) : (
                    <TextField
                      error={callerIdError}
                      fullWidth
                      helperText={callerIdhelperText}
                      label="Caller ID"
                      name="phoneNumber"
                      onChange={handleChange}
                      style={{ marginTop: 10 }}
                      type="number"
                      value={phoneNumber}
                    />
                  )}
                </FormControl>
              </DialogContent>
              <DialogActions>
                {callerIdValidationCode && Object.keys(callerIdValidationCode).length > 0 ? (
                  <div>
                    <PortalButton
                      color="primary"
                      onClick={() => {
                        dispatch(getAccountsByUser());
                        handleClose();
                      }}
                    >
                      SUCCEEDED
                    </PortalButton>
                    <PortalButton color="primary" onClick={handleClose}>
                      FAILED
                    </PortalButton>
                  </div>
                ) : (
                  <div>
                    <PortalButton color="primary" onClick={verifyCaller}>
                      Call
                    </PortalButton>
                    <PortalButton color="secondary" onClick={handleClose}>
                      Cancel
                    </PortalButton>
                  </div>
                )}
              </DialogActions>
            </Dialog>

            {/* <FormControl fullWidth className={classes.formControl}>
              {accounts && (
                <Autocomplete
                  id="combo-box-demo"
                  value={selCallerIdAccount}
                  className={classes.autoComplete}
                  data-tour="rtSelAcct"
                  onChange={(event, newValue) => {
                    console.log('newValue autocomple', newValue);
                    handleAccountChange(newValue);
                    //setAcAccount(newValue)
                  }}
                  options={
                    accounts ? accounts.slice().sort((a, b) => a.name.localeCompare(b.name)) : []
                  }
                  getOptionLabel={(option) => option.number + ' - ' + option.name}
                  fullWidth
                  renderInput={(params) => {
                    if (params) {
                      return (
                        <TextField {...params} label={t('Select Account')} variant="outlined" />
                      );
                    }
                    return;
                  }}
                />
              )}
            </FormControl> */}
            {/* {searchAccounts &&
              searchAccounts.length > 0 &&
              searchAccounts.map(
                (row) =>
                  JSON.parse(row.twilioNumber).length > 0 &&
                  JSON.parse(row.twilioNumber).map((val) => {
                    if (val.verificationStatus == 'VERIFIED') {
                      return (
                        <div>
                          <TableContainer component={Paper} style={{ marginTop: 20 }}>
                            <Table className={classes.table} size="small" aria-label="table">
                              <TableHead>
                                <TableCell>Caller Id</TableCell>
                                <TableCell>Status</TableCell>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell scope="row">{val.phoneNumber}</TableCell>
                                  <TableCell scope="row">{val.hostingStatus}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={callerIds.totalElements ? callerIds.totalElements : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </div>
                      );
                    }
                  }),
              )} */}

            {/* {searchAccounts &&
              searchAccounts.length > 0 &&
              searchAccounts.map(
                (row) =>
                  JSON.parse(row.twilioNumber).length == 0 && (
                    <Typography style={{ textAlign: 'center', padding: 20 }}>
                      {' '}
                      Verified Caller ID option is disabled on the selected account.
                    </Typography>
                  ),
              )} */}
          </CardContent>
          <CardActions className={classes.cardActions} style={{ justifyContent: 'flex-end' }}>
            <div>
              <PortalButton color="secondary" />
              {/* <PortalButton color="secondary" onClick={handleUp}><ArrowUpwardIcon /></PortalButton>
                    <PortalButton color="secondary" onClick={handleDown}><ArrowDownwardIcon /></PortalButton> */}
              {/* <PortalButton color="secondary" onClick={() => handleOpen("edit")}>Change NickName</PortalButton> */}
              {/* <PortalButton color="secondary" >Sort By Name </PortalButton>
                    <PortalButton color="secondary" >Sort By Account# </PortalButton> */}
            </div>

            {/* <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={paginationCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div> */}
          </CardActions>
        </Card>
      </Grid>
    </StyledRoot>
  );
}
