import { useEffect, useState } from 'react';
// import 'fast-text-encoding'; // TODO: review it's need
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { lastMsgQuerySelector, selReceiverSelector } from '../messageSlice';
import {
  Button,
  FormControl,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { timezoneSelector } from '../../../app/authSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-virtualized/styles.css';
import { getSentimentMessagesForUser, sentimentMessagesSelector } from '../sentimentMessageSlice';
import PortalButton from '../../shared/components/PortalButton';

const PREFIX = 'SentimentQueryFilterDialog';

const classes = {
  root: `${PREFIX}-root`,
  buttonGroup: `${PREFIX}-buttonGroup`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flex: 1,
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const SearchTypes = {
  lastOneWeek: 'Recent (last 7 days)',
  lastOneMonth: 'Recent (last 31 days)',
  customDate: 'Date',
};

const DefaultMessageLimit = 50;
const MessageLimitOptions = [10, 25, 50, 100, 200];

export default function SentimentQueryFilterDialog({
  openDialog,
  fetchMessages,
  unSetFetchMessages,
  propagateOnClose,
}) {
  const messages = useSelector(sentimentMessagesSelector);
  const dispatch = useDispatch();
  const selReceiver = useSelector(selReceiverSelector);
  const [searchType, setSearchType] = useState(SearchTypes.lastOneWeek);
  const [messageLimit, setMessageLimit] = useState(DefaultMessageLimit);
  const defaultTimezone = useSelector(timezoneSelector);
  const [selectedDate, setSelectedDate] = useState(
    moment().tz(defaultTimezone).startOf('day').format('YYYY-MM-DDTHH:mm'),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().tz(defaultTimezone).endOf('day').format('YYYY-MM-DDTHH:mm'),
  );
  const [starttimeFilterError, setStarttimeFilterError] = useState(false);
  const [starttimeFilterErrorText, setStarttimeFilterErrorText] = useState(
    'Start Date is greater than End date',
  );
  const [endtimeFilterError, setEndtimeFilterError] = useState(false);
  const [endtimeFilterErrorText, setEndtimeFilterErrorText] = useState(
    'End Date is less than Start date',
  );

  const lastMsgQuery = useSelector(lastMsgQuerySelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const dateOpen = Boolean(anchorEl);
  const id = dateOpen ? 'simple-popover' : undefined;
  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState([]);
  const queryReceivedDateFormat = 'YYYY-MM-DD HH:mm:ss';

  useEffect(() => {
    if (fetchMessages) {
      handleSave();
      unSetFetchMessages();
    }
  }, [fetchMessages]);

  useEffect(() => {
    if (messages) {
      setCount({ prev: 0, next: 10 });
      setCurrent(messages.slice(0, 10));
      setHasMore(true);
    }
  }, [messages, selReceiver]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (lastMsgQuery === 'reload') {
      handleSave();
    }
  }, [lastMsgQuery]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'searchType':
        setSearchType(event.target.value);
        break;
      case 'messageLimit':
        setMessageLimit(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (event) => {
    switch (event.target.name) {
      case 'startDate':
        if (moment(event.target.value).valueOf() - moment(selectedEndDate).valueOf() > 0) {
          setStarttimeFilterError(true);
        } else {
          setStarttimeFilterError(false);
        }
        setEndtimeFilterError(false);

        setSelectedDate(event.target.value);
        break;
      case 'endDate':
        if (moment(event.target.value).valueOf() - moment(selectedDate).valueOf() < 0) {
          setEndtimeFilterError(true);
        } else {
          setEndtimeFilterError(false);
        }
        setStarttimeFilterError(false);
        setSelectedEndDate(event.target.value);
        break;
      default:
    }
  };

  const handleClose = () => {
    propagateOnClose();
  };

  const handleSave = () => {
    let query = {};
    switch (searchType) {
      case SearchTypes.lastOneMonth:
        query = {
          ...query,
          startDate: moment.utc().subtract(30, 'd').format(queryReceivedDateFormat),
          endDate: moment.utc().format(queryReceivedDateFormat),
        };
        break;
      case SearchTypes.lastOneWeek:
        query = {
          ...query,
          startDate: moment.utc().subtract(7, 'd').format(queryReceivedDateFormat),
          endDate: moment.utc().format(queryReceivedDateFormat),
        };
        break;
      case 'Date':
        query = {
          ...query,
          startDate: moment
            .tz(selectedDate, queryReceivedDateFormat, defaultTimezone)
            .format(queryReceivedDateFormat),
          endDate: moment
            .tz(selectedEndDate, queryReceivedDateFormat, defaultTimezone)
            .format(queryReceivedDateFormat),
        };
        break;
      default:
    }
    query = {
      ...query,
      PagerNumber: selReceiver,
      limit: messageLimit ? messageLimit : DefaultMessageLimit,
    };
    dispatch(getSentimentMessagesForUser(query));
    handleClose();
  };

  useEffect(() => {
    if (selReceiver !== 0) {
      handleSave();
    }
  }, [selReceiver]);

  return (
    <StyledDialog
      aria-labelledby="simple-dialog-title"
      className={classes.dialog}
      data-tour="rtQueryMsg-parent"
      disableBackdropClick
      maxWidth="md"
      onClose={handleClose}
      open={openDialog}
    >
      <DialogTitle id="simple-dialog-title"> {t('Change Sentiment Message Query')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" />
        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="search-label">{t('Search Type')}</InputLabel>
          <Select
            id="search"
            labelId="search-label"
            name="searchType"
            onChange={handleChange}
            value={searchType}
          >
            {[SearchTypes.lastOneWeek, SearchTypes.lastOneMonth, SearchTypes.customDate].map(
              (value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>

        {searchType === SearchTypes.customDate && (
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={selectedDate}
              error={starttimeFilterError}
              helperText={starttimeFilterError && starttimeFilterErrorText}
              id="datetime-local"
              label="Start Time"
              name="startDate"
              onChange={handleDateChange}
              type="datetime-local"
              value={selectedDate}
              variant="standard"
            />

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={selectedEndDate}
              error={endtimeFilterError}
              helperText={endtimeFilterError && endtimeFilterErrorText}
              id="datetime-local2"
              label="End Time"
              name="endDate"
              onChange={handleDateChange}
              type="datetime-local"
              value={selectedEndDate}
              variant="standard"
            />
          </FormControl>
        )}

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="messageLimit-label">{t('Message Limit')}</InputLabel>
          <Select
            id="messageLimit"
            labelId="messageLimit-label"
            name="messageLimit"
            onChange={handleChange}
            value={messageLimit}
          >
            {MessageLimitOptions.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <PortalButton
          color="primary"
          disabled={
            searchType === SearchTypes.customDate && (starttimeFilterError || endtimeFilterError)
          }
          onClick={handleSave}
          variant="contained"
        >
          {t('Query')}
        </PortalButton>
        <PortalButton color="secondary" onClick={handleClose} variant="contained">
          {t('Cancel')}
        </PortalButton>
      </DialogActions>
    </StyledDialog>
  );
}
