import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export const XLSXWritingOptionBookType = {
  xlsx: 'xlsx',
  xlsm: 'xlsm',
  xlsb: 'xlsb',
  xls: 'xls',
  xla: 'xla',
  biff8: 'biff8',
  biff5: 'biff5',
  biff2: 'biff2',
  xlml: 'xlml',
  ods: 'ods',
  fods: 'fods',
  csv: 'csv',
  txt: 'txt',
  sylk: 'sylk',
  html: 'html',
  dif: 'dif',
  rtf: 'rtf',
  prn: 'prn',
  eth: 'eth',
};

export const XLSXWritingOptionType = {
  base64: 'base64',
  binary: 'binary',
  buffer: 'buffer',
  file: 'file',
  array: 'array',
  string: 'string',
};

export const FileMimeTypes = {
  textPlain: 'text/plain',
  textHtml: 'text/html',
  applicationPdf: 'application/pdf',
  applicationJson: 'application/json',
  applicationXml: 'application/xml',
  imageJpeg: 'image/jpeg',
  imagePng: 'image/png',
  imageGif: 'image/gif',
  audioMpeg: 'audio/mpeg',
  audioWav: 'audio/wav',
  videoMp4: 'video/mp4',
  videoQuicktime: 'video/quicktime',
  applicationVndMsExcel: 'application/vnd.ms-excel',
  applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  applicationVndMsPowerpoint: 'application/vnd.ms-powerpoint',
  applicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  applicationMsword: 'application/msword',
  applicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  applicationZip: 'application/zip',
  applicationOctetStream: 'application/octet-stream',
};

export const exportJsonDataToCSVFile = ({
  jsonData,
  filename,
  fileExtension,
  fileMimeType,
  charset,
}) => {
  saveDataAsFile(XLSXWritingOptionBookType.csv, {
    jsonData,
    filename,
    fileExtension: fileExtension ? fileExtension : 'csv',
    fileMimeType: fileMimeType
      ? fileMimeType
      : FileMimeTypes.applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet,
    charset,
  });
};

export const saveDataAsFile = (
  bookType,
  { jsonData, filename, fileExtension, fileMimeType, charset },
) => {
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType, type: XLSXWritingOptionType.array });
  const data = new Blob([excelBuffer], {
    type: `${fileMimeType};${charset ? charset : 'charset=UTF-8'}`,
  });
  saveAs(data, `${filename}.${fileExtension}`);
};
